'use strict'

import { LOGIN } from '../store/modules/security/mutations-types'

/**
 * Mixin to update user information saved in the localStorage and redirect to next page.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 11:41 p. m.
 */
const authenticationMixin = {
  methods: {
    /**
     * Persist user information.
     *
     * @author C Camilo Castellanos
     * @version 1.0.0
     * @since 19/12/2019 11:42 p. m.
     */
    $_authenticationMixin_persistUserInformation (userInfo) {
      this.$store.commit(LOGIN, userInfo)
      this.$router.go({ name: 'Home' })
    }
  }
}

export default authenticationMixin
