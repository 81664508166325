<template>
  <div class="b-authentication">
    Login Backoffice
  </div>
</template>

<script>
import authenticationMixin from '../../../../../mixins/authenticationMixin'
import handleErrorMixin from '../../../../../mixins/handleErrorMixin'

/**
 * Component to authenticate backoffice users in the application.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 20/12/2019 1:15 a. m.
 */
export default {
  name: 'AuthenticationBackoffice',
  mixins: [authenticationMixin, handleErrorMixin],
  data () {
    return {
      model: {}
    }
  },
  methods: {
    /**
     * Function to authenticate user in the application.
     *
     * @author C Camilo Castellanos
     * @version 1.0.0
     * @since 20/12/2019 1:16 a. m.
     */
    authenticateUser () {
      this.axios.post('', this.model).then((response) => {
        this.$_authenticationMixin_persistUserInformation(response.data)
        this.$router.go({ name: 'Home' })
      }).catch((error) => {
        this.$_handleErrorMixin_generateError(error)
      })
    }
  }
}
</script>
